import { jwtDecode } from 'jwt-decode';

import { RootState } from './store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Migration = (state: any) => any;
type Migrations = Record<number, Migration>;

export const storeMigrations: Migrations = {
  0: (state: RootState) => state,

  // 1: Add new `chat.availableThreads` field to user.currentUser.subInfo if it doesn't exist and rest of the objects exists
  1: (state: RootState) => {
    const currentUser = state.user.currentUser;
    if (!currentUser) return state;

    const subInfo = currentUser.subInfo;
    if (!subInfo) return state;

    return {
      ...state,
      user: {
        ...state.user,
        currentUser: {
          ...currentUser,
          subInfo: {
            ...subInfo,
            chat: {
              availableThreads: subInfo.chat?.availableThreads || 0,
            },
          },
        },
      },
    };
  },

  // 2: Add `loginType`, `accessTokenExpiresAt` and `refreshTokenExpiresAt` fields to user.session
  2: (state: RootState) => {
    const session = state.user.session;
    if (!session) return state;

    const { accessToken, refreshToken } = session;
    const decodedAccessToken = jwtDecode(accessToken);
    const decodedRefreshToken = jwtDecode(refreshToken);
    const accessTokenExpiresAt = decodedAccessToken.exp || 0;
    const refreshTokenExpiresAt = decodedRefreshToken.exp || 0;

    return {
      ...state,
      user: {
        ...state.user,
        session: {
          ...session,
          accessTokenExpiresAt,
          refreshTokenExpiresAt,
          loginType: 'email',
        },
      },
    };
  },
};
