import { Box } from '@mui/material';
import { useMemo } from 'react';

import { Pages, useGetPagePathWithLang } from '@/navigation';
import { useAppTranslation } from '@/translation';

import { InTextButton, Text } from '../elements';

import { ControlledCheckbox } from './ControlledCheckbox/ControlledCheckbox';

interface ControlledCheckboxForAcceptTermsProps {
  type: 'signUp' | 'payment';
}

export const ControlledCheckboxForAcceptTerms = ({ type }: ControlledCheckboxForAcceptTermsProps) => {
  const { t } = useAppTranslation();
  const termsOfServicePath = useGetPagePathWithLang(Pages.termsOfService);
  const privacyPolicyPath = useGetPagePathWithLang(Pages.privacyPolicy);

  const buttonLabel = type === 'payment' ? t('subscription:iConfirmAndPay') : t('user:signUp');

  const label = useMemo(
    () => (
      <Box lineHeight={1}>
        <Text
          variant="bodySmall"
          translationKey="common:acceptTermsCheckboxLabelPart1"
          translationParams={{ buttonLabel }}
        />
        <InTextButton
          target="_blank"
          fontSize={12}
          href={termsOfServicePath}
          labelId="common:acceptTermsCheckboxLabelPart2"
        />
        <Text variant="bodySmall" translationKey="common:acceptTermsCheckboxLabelPart3" />
        <InTextButton
          target="_blank"
          fontSize={12}
          href={privacyPolicyPath}
          labelId="common:acceptTermsCheckboxLabelPart4"
        />
        <Text variant="bodySmall" translationKey="common:acceptTermsCheckboxLabelPart5" />
      </Box>
    ),
    [buttonLabel, privacyPolicyPath, termsOfServicePath],
  );

  return <ControlledCheckbox label={label} id="terms" name="terms" />;
};
