import { Capacitor } from '@capacitor/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '@/hooks';
import { captureError } from '@/services';

import { ssoLogin } from '../api';
import { setSession } from '../store';

export const useRefreshSessionBasedOnGoogleLoginNative = () => {
  const dispatch = useAppDispatch();
  const { session } = useAppSelector((state) => state.user);

  const isLoggedInUsingGoogle = session?.loginType === 'google';

  const recreateSessionUsingGoogle = useCallback(async () => {
    if (!Capacitor.isNativePlatform()) return;

    try {
      const userObject = await GoogleAuth.signIn();
      const accessToken = userObject.authentication.accessToken;

      const tokens = await ssoLogin({
        type: 'google',
        payload: { accessToken },
      });

      if (tokens) dispatch(setSession({ ...tokens, loginType: 'google' }));
      else dispatch(setSession(null));

      return tokens.accessToken;
    } catch (error) {
      captureError(error);
      dispatch(setSession(null));
      return null;
    }
  }, [dispatch]);

  return { isLoggedInUsingGoogle, recreateSessionUsingGoogle };
};
