import { useTranslation } from 'react-i18next';

export enum Pages {
  // outside app
  landing = 'https://www.refereewise.com/',
  termsOfService = 'https://www.refereewise.com/:lang/terms-of-service',
  privacyPolicy = 'https://www.refereewise.com/:lang/privacy-policy',
  cookiesPolicy = 'https://www.refereewise.com/:lang/cookies-policy',

  // In app
  // Public
  demo = '/demo',
  login = '/login',
  removedAccount = '/removed-account',
  recoverPassword = '/recover-password',
  resetPassword = '/reset-password',
  registration = '/registration',
  registrationSuccess = '/registration/success',
  registrationConfirm = '/registration/confirm',

  // Home
  home = '/home',

  // Assistant chat
  assistantChat = '/assistant-chat',

  // Quiz
  quiz = '/quiz/:id',
  quizSummary = '/quiz/:id/summary',

  // User
  history = '/history',
  userProfile = '/me',
  userSubscriptionSummary = '/me/subscription/summary',
  userSubscriptionPaymentStatus = '/me/subscription/payment/:orderId',
}

export const getPagePath = (page: Pages, params?: Record<string, string | number>): string => {
  let path: string = page;

  if (params) {
    Object.keys(params).forEach((key) => {
      path = path.replace(`:${key}`, params[key].toString());
    });
  }

  return path;
};

export const useGetPagePathWithLang = (page: Pages, params?: Record<string, string | number>): string => {
  const { i18n } = useTranslation();
  return getPagePath(page, { lang: i18n.language, ...params });
};
