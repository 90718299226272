import { Capacitor } from '@capacitor/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useAppDispatch } from '@/hooks';

import { logoutUser as logoutUserStoreAction } from '../store';

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const logout = useCallback(async () => {
    // await logoutUserRequest();

    // INFO: Logout from Google to prevent auto-login and add available to change account
    if (Capacitor.isNativePlatform()) await GoogleAuth.signOut();

    queryClient.clear();
    queryClient.removeQueries();
    dispatch(logoutUserStoreAction());
    // ProtectedRoute will redirect to login page if session is null
  }, [dispatch, queryClient]);

  return { logout };
};
