import { Capacitor } from '@capacitor/core';
import { Box, Container } from '@mui/material';
import { useEffect } from 'react';

import { BackButton, BackToHomePageButton, Header } from '@/components/elements';

interface Props {
  children: React.ReactNode;
  overflowVisible?: boolean;
  showBackButton?: boolean;
  removeBottomPadding?: boolean;
  disableHeaderLink?: boolean;
  scrollTopOnEntry?: boolean;
  showHomeButton?: boolean;
}

export const Page = ({
  children,
  disableHeaderLink,
  overflowVisible,
  showBackButton,
  removeBottomPadding,
  scrollTopOnEntry,
  showHomeButton,
}: Props) => {
  useEffect(() => {
    if (scrollTopOnEntry) window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [scrollTopOnEntry]);

  return (
    <Box flex={1} width="100%" overflow={overflowVisible ? 'visible' : 'auto'}>
      <Container maxWidth="md" sx={{ px: 0, pb: removeBottomPadding ? 0 : 10 }}>
        <Header showHomeButton={showHomeButton} showBackButton={showBackButton} disableHeaderLink={disableHeaderLink} />

        {showBackButton && !Capacitor.isNativePlatform() && (
          <Box mb={3}>
            <BackButton />
          </Box>
        )}
        {showHomeButton && !Capacitor.isNativePlatform() && (
          <Box mb={3}>
            <BackToHomePageButton />
          </Box>
        )}

        <Box px={3}>{children}</Box>
      </Container>
    </Box>
  );
};
