import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';

import { isDev } from '@/config';
import { CookiesKeys } from '@/constants/cookiesKeys';
import { captureError } from '@/services';

import { Language, languagesResources } from './locales';

const cookieDomain = isDev ? undefined : 'refereewise.com';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnNull: false,
    fallbackLng: 'en-gb',
    lowerCaseLng: true,
    compatibilityJSON: 'v4',
    resources: languagesResources,
    missingKeyHandler: (lng, ns, key) => {
      captureError(new Error(`Missing translation: ${lng} ${ns} ${key}`));
    },
    ns: ['common'],
    defaultNS: 'common',
    detection: {
      order: ['cookie', 'localStorage', 'navigator'],
      caches: ['cookie', 'localStorage'],
      cookieDomain,
      lookupCookie: CookiesKeys.language,
      lookupLocalStorage: CookiesKeys.language,
      convertDetectedLanguage(lng) {
        if (lng === 'pl-PL') return Language.PL;
        if (lng === 'en-GB') return Language.EN_GB;

        return Language.EN_GB;
      },
      cookieOptions: {
        path: '/',
        expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000), // 1 year
        domain: cookieDomain,
      },
    },
  });

z.setErrorMap(zodI18nMap);

export { i18n };
