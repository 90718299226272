import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitErrorHandler, SubmitHandler, useForm, useFormContext } from 'react-hook-form';
import { z } from 'zod';

import { ArticlesArray, GroupedArticles, GroupsOfArticles, QuizMode } from '@/appTypes/quiz';
import { useCreateQuiz } from '@/features/Quiz/hooks';
import { useFeatureAvailable } from '@/features/User';
import { useNotify } from '@/hooks';

export const possibleNumberOfQuestions = [10, 20, 30];

const formSchema = z.object({
  selectedGroups: z.array(z.nativeEnum(GroupsOfArticles)),
  questionNumber: z.number().min(1).max(31),
  mode: z.nativeEnum(QuizMode),
});

type CreateQuizFormValues = z.infer<typeof formSchema>;

const getInitialValues = (isLearningModeAvailable: boolean): CreateQuizFormValues => ({
  selectedGroups: [],
  questionNumber: possibleNumberOfQuestions[2],
  mode: isLearningModeAvailable ? QuizMode.learning : QuizMode.exam,
});

export const useCreateQuizForm = () => {
  const { notify } = useNotify();

  const { learningMode: isLearningModeAvailable } = useFeatureAvailable();

  const { createQuiz } = useCreateQuiz();

  const form = useForm<CreateQuizFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: getInitialValues(isLearningModeAvailable),
  });
  const { handleSubmit, formState } = form;

  const onSubmit: SubmitHandler<CreateQuizFormValues> = async (data) => {
    const selectedArticles = data.selectedGroups.map((group) => GroupedArticles[group]).flat();
    const selectedArticlesNumbers = selectedArticles.map((article) => ArticlesArray.indexOf(article) + 1);

    await createQuiz({
      selectedArticlesNumbers,
      questionNumber: data.questionNumber,
      mode: data.mode,
    });
  };

  const onError: SubmitErrorHandler<CreateQuizFormValues> = (errors) => {
    const errorMessage = Object.values(errors)
      .map((error) => error.message)
      .join('\n');

    notify('error', errorMessage);
  };

  const onSubmitPress = handleSubmit(onSubmit, onError);

  const isSubmitting = formState.isSubmitting;

  return {
    form,
    onSubmitPress,
    isSubmitting,
  };
};

export const useCreateQuizFormContext = () => useFormContext<CreateQuizFormValues>();
