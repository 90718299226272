import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';

import { useNotify } from '@/hooks';
import { captureError } from '@/services';
import { useAppTranslation } from '@/translation';

import { ConfirmRegistrationPayload, ConfirmRegistrationProcessedResponse, confirmRegistration } from '../api';
import { setSession } from '../store';

import { useNavigateFromLogin, useRefreshCurrentUserData } from '.';

export const useConfirmRegistration = () => {
  const dispatch = useDispatch();
  const { navigateToHomeOrFrom } = useNavigateFromLogin();
  const { refreshCurrentUserData } = useRefreshCurrentUserData();
  const { notify } = useNotify();
  const { t } = useAppTranslation();

  const confirmRegistrationRequest = async (
    payload: ConfirmRegistrationPayload,
  ): Promise<ConfirmRegistrationProcessedResponse> => {
    try {
      const response = await confirmRegistration(payload);
      dispatch(setSession({ ...response, loginType: 'email' }));
      await refreshCurrentUserData();
      notify('success', t('user:yourEmailHasBeenConfirmed'));
      navigateToHomeOrFrom();
      return response;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        const { response } = err;

        if (response?.status === 401) {
          notify('error', t('user:emailVerificationProcessFailed'));
          captureError(err);
          throw new Error('emailVerificationProcessFailed');
        }
      }

      notify('error', t('common:errorOccurredTryAgain'));
      captureError(err);
    }
    return {
      accessToken: '',
      refreshToken: '',
    };
  };

  const { data, mutateAsync, isPending: isLoading, status } = useMutation({ mutationFn: confirmRegistrationRequest });

  return {
    confirmRegistration: mutateAsync,
    isLoading,
    data,
    status,
  };
};
