import { Button, ButtonProps } from '@mui/material';

import { TranslationKeys, useAppTranslation } from '@/translation';

interface InTextButtonProps extends ButtonProps {
  labelId?: TranslationKeys;
  fontSize?: number;
  target?: string;
}

export const InTextButton = ({ children, fontSize, labelId, ...props }: InTextButtonProps) => {
  const { t } = useAppTranslation();

  return (
    <Button
      variant="text"
      sx={{ fontSize: fontSize || 14, paddingY: 0, paddingX: 1, textTransform: 'none', height: 16.5 }}
      {...props}
    >
      {children}
      {labelId && t(labelId)}
    </Button>
  );
};
