export enum StandardGtmEvent {
  login = 'login',
  signUp = 'sign_up',
  purchase = 'purchase',
  beginCheckout = 'begin_checkout',
  user = 'user',
}

export enum AppThemeGtmEvent {
  change = 'rw_app-theme_change',
}

export enum OrderGtmEvent {
  extendFromHome = 'rw_order_extend-from-home',
  renewFromHome = 'rw_order_renew-from-home',
}

export enum QuizGtmEvent {
  create = 'rw_quiz_create',
  checkQuestion = 'rw_quiz_check-question',
  finish = 'rw_quiz_finish',
  resumeQuiz = 'rw_quiz_resume',
  reportQuestion = 'rw_quiz_report-question',
  createSimilar = 'rw_quiz_create-similar',
}

export enum SurveyGtmEvent {
  viewForm = 'rw_survey_view-form',
  sendAnswer = 'rw_survey_send-answer',
  skip = 'rw_survey_skip',
}

interface EventECommerceObjectItem {
  item_id: string;
  quantity: number;
}

export interface EventEcommerceObject {
  transaction_id?: string;
  value: number;
  currency: string;
  items: EventECommerceObjectItem[];
}

export enum AssistantGtmEvent {
  startNewThread = 'rw_assistant_start-new-thread',
  continueThread = 'rw_assistant_continue-thread',
  rateMessage = 'rw_assistant_rate-message',
  checkDemoQuestions = 'rw_assistant_check-demo-questions',
  checkDemoAnswer = 'rw_assistant_check-demo-answer',
}
