import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Quiz } from '@/appTypes';
import { axiosInstance } from '@/services';
import { useAppTranslation } from '@/translation';

import { adjustQuizUserAnswerForFrontend } from '../utils';

export type GetQuizResponse = Quiz | undefined;

interface UseGetQuizParams {
  onSuccess?: (data: GetQuizResponse) => void;
  demo?: boolean;
}

export const getQuizQueryKey = (quizId: string | undefined) => ['quiz', quizId] as const;

export const useGetQuiz = (params?: UseGetQuizParams) => {
  const { currentLanguage } = useAppTranslation();
  const { onSuccess, demo } = params || {};
  const { id } = useParams<{ id: string }>();

  const getQuizRequest = async () => {
    let response;

    if (demo) {
      response = await axiosInstance.get<GetQuizResponse>(`questions/quiz/`, {
        params: {
          language: currentLanguage,
        },
      });
    } else {
      response = await axiosInstance.get<GetQuizResponse>(`/quizzes/${id}/`);
    }

    if (!response.data) return undefined;
    return adjustQuizUserAnswerForFrontend(response.data);
  };

  const { data, isLoading, isFetching } = useQuery({
    queryKey: getQuizQueryKey(id),
    queryFn: getQuizRequest,
  });

  useEffect(() => {
    if (data) onSuccess?.(data);
  }, [data, onSuccess]);

  return { quiz: data, isLoading, isFetching };
};
